import axios from 'axios'
import { API_NAPCOBRANZAS } from 'config/api.config'
import UserService from 'config/UserService'

export const getBillingCompany = async () => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/fact/information/${UserService.company()}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const postBillingCompany = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/fact/register/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

// type = 01 || 03
// export const getVouchers = async (type) => {
//   const res = await axios.get(`${API_NAPCOBRANZAS}/payment/facturation-pay/${type}/${UserService.company()}`, {
//     headers: { Authorization: UserService.token() },
//   });
//   return res.data;
// };

export const getCreditNote = async () => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/creditnote/${UserService.company()}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const generateCreditNote = async (voucherId, data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/creditnote/${voucherId}/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const sendEmailVoucher = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/fact/send-email/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

// typeVoucher => 01=factura; 03=boleta
export const getPendingVouchers = async (typeVoucher) => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/payment/pending/${typeVoucher}/${UserService.company()}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const sendSunat = async (idOperation) => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/fact/sendSunat/${idOperation}/${UserService.company()}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const sendMassiveBallots = async (ids) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/fact/massiveSunat/${UserService.company()}`, ids, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

// OBTENER ENVÍOS MASIVOS

export const getMassiveComprobants = async () => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/massive/${UserService.company()}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

// REVISAR ESTADO DEL COMPROBANTE

export const checkStatusComprobants = async (id) => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/massive/status-ticket/${id}/${UserService.company()}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

// VOLVER A ENVIAR EL MASIVO

export const resendMassiveToSunat = async (id) => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/payment/resend-massive-sunat/${id}/${UserService.company()}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

// ANULAR BEFORE SEND TO SUNAT

export const anularBoleta = async (id) => {
  const res = await axios.put(`${API_NAPCOBRANZAS}/voucher/anulated/${id}`, {}, { headers: { Authorization: UserService.token() } })
  return res.data
}

export const anularManual = async (id) => {
  const res = await axios.put(`${API_NAPCOBRANZAS}/payment/anulated-manual/${id}`, {}, { headers: { Authorization: UserService.token() } })
  return res.data
}

export const getVouchersById = async (id) => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/payment/status/id/${id}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const getCreditNoteById = async (id) => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/creditnote/id/${id}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

// PAGOS
export const getPaymentsDebts = async () => {
  const res = await axios.post(
    `${API_NAPCOBRANZAS}/payment/debts/${UserService.company()}`,
    {},
    {
      headers: { Authorization: UserService.token() }
    }
  )
  return res.data
}
